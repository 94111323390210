<template>
    <!-- <Defaultlayout :userprofile="userprofile"> -->
    <!-- <PageSlider></PageSlider> -->
    <section class="about-us">

        <div class="container" style="margin-top: -111px;">
            <div class="logo">
                <img :src="brand_logo.logo" style=" width: 110px; height: 57.58px;" />
            </div>
            <h1 class="page-heading text-center">Privacy Policy</h1>
            <h2 class="section-heading" style="margin-bottom: 10px;">Ghar Bazaar’s Privacy Policy</h2>
            <h6 style="margin-bottom: 10px;">Effective Date: Dec 18, 2023</h6>
            <h6>Thank you for choosing Ghar Bazaar! This Privacy Policy outlines how we collect, use, disclose, and
                protect your information when you use our Ghar Bazaar home delivery mobile app available on the
                Google Play store, Apple’s app store, or our website <a href="https://www.gharbazaar.com"
                    target="_blank">GharBazaar.com</a>.</h6>

            <h6>By using our mobile app and/or website, you agree to the terms outlined in this Privacy Policy.</h6>

            <p style="margin-bottom: 0px;"><strong>Information We Collect:</strong></p>
            <ol>
                <li>
                    Personal Information:
                    <ol type="a">
                        <li> Contact details (such as name, email address, phone number)</li>
                        <li> Delivery address</li>
                        <li> Payment information</li>
                    </ol>
                </li>
                <li>
                    Device Information:
                    <ol type="a">
                        <li>Device type</li>
                        <li>Operating system</li>
                        <li>Unique device identifiers</li>
                        <li>IP address</li>
                    </ol>
                </li>
                <li>
                    Usage Information:
                    <ol type="a">
                        <li>App usage data</li>
                        <li>Preferences and settings</li>
                    </ol>
                </li>
                <li>Customer Service: Ghar Bazaar will collect information when you interact with
                    Ghar Bazaar’s customer service in order to accurately categorize and respond to customer
                    inquiries and investigate breaches of the terms.</li>
                <li>
                    Cookies: Like most websites, Ghar Bazaar uses cookies and web log files to
                    track site usage and trends, to improve the quality of service, to customize your experience on
                    Ghar Bazaar. A cookie is a tiny data file that resides on your computer, mobile phone, or other
                    devices, and allows us to recognize you as a User when you return to the Ghar Bazaar website
                    using the same computer and web browser. You can remove or block cookies using the settings in
                    your browser, but in some cases doing so may impact your ability to use Ghar Bazaar effectively.
                    Ghar Bazaar may use cookie-equivalent technology for the limited purpose of enhancing the
                    security of Ghar Bazaar’s services.
                </li>
                <li>
                    Product Images: <br> We may collect product images for the purpose of enhancing your
                    shopping experience.
                </li>
                <li>
                    User Image:
                    <ol type="a">
                        <li>Voluntary Submission: Users may choose to voluntarily submit images, photographs, or other
                            visual content (collectively referred to as "images") to our platform.</li>
                        <li>Driver Captured Images: Our company drivers may capture images, including photographs or visual
                            proof, during the delivery process for Proof of Delivery (POD) purposes.</li>
                    </ol>
                </li>
            </ol>
            <p style="margin-bottom: 0px;"><strong>How We Use Your Information:</strong></p>
            <ol>
                <li>Order Processing: To process and deliver your grocery orders.</li>
                <li>Account Management: To create and manage your user account.</li>
                <li>Communication: To send order confirmations, updates, and promotional offers.
                </li>
                <li>Improvements: To enhance and personalize user experience, and to improve our
                    products and services.</li>
                <li>Usage of Collected Product Images: Product images collected are used solely for
                    the purpose of displaying products accurately within the app.</li>
                <li>
                    Data Sharing:
                    <ol type="a">
                        <li>Service Providers: We may share information with third-party service
                            providers for order fulfillment, payment processing, support communications, and
                            analytics.</li>
                        <li>Legal Compliance: To comply with legal requirements or respond to
                            lawful requests and legal processes.</li>
                        <li>Protection of Product Images: All images used on the app, including
                            product images, are copyrighted and the property of Ghar Bazaar LLC. They cannot be
                            licensed or used by any third party.</li>
                    </ol>
                </li>
                <li>
                    Firebase Crashlytics
                </li>
                <li>
                    Log Data <br>
                    We want to inform you that whenever you use our Service, in a case of an error in the app we collect
                    data and information (through third-party products) on your phone called Log Data. This Log Data may
                    include information such as your device Internet Protocol (“IP”), device name, operating system version,
                    the configuration of the app when utilizing our Service, the time and date of your use of the Service,
                    and other statistics.
                </li>
                <li>User images : These images are collected solely for enhancing user experience within our services, such
                    as profile customization, social interaction features, or other functionalities explicitly outlined
                    within our application.</li>
                <li>Drivers captured images for POD (Proof Of Delivery): These images are primarily used to verify and
                    record the successful completion of deliveries, ensuring the accuracy and integrity of our services.
                    Captured images are restricted to the context of the delivery location and related POD requirements.
                    Images are retained for a limited period necessary for auditing, dispute resolution, or as required by
                    applicable laws and regulations. Access to POD images is limited to authorized personnel and is strictly
                    used for the intended purposes. does not share POD images with third parties, except when required by
                    law or in instances where user consent has been obtained.</li>
            </ol>
            <p style="margin-bottom: 0px;"><strong>Your Choices:</strong></p>
            <ol type="1" style="margin-bottom: 0rem;">
                <li>
                    <h6>Account Information: You can update or delete your account information through the app settings.
                    </h6>
                </li>
                <li>
                    <h6>Users retain full ownership rights to the images they submit as part of the profile picture. Ghar
                        Bazaar does not claim any ownership over user-uploaded profile picture. Users have the right to
                        manage, edit, or remove their images from our platform at any time through the designated settings
                        or features provided within the application.</h6>
                </li>
                <li>
                    <h6>POD images: By using our services, users implicitly consent to the capture and use of POD images for
                        delivery verification purposes. Access and Deletion: Users have the right to request access to the
                        images captured during their deliveries and may request their deletion, subject to applicable laws.
                    </h6>
                </li>
            </ol>
            <p style="margin-bottom: 0px;"><strong>Security:</strong></p>
            <h6>We implement industry-standard security measures to protect your information. However, no method of
                transmission over the internet or electronic storage is completely secure.</h6>

            <p style="margin-bottom: 0px;"><strong>Children's Privacy:</strong></p>
            <h6>Our services are not intended for users under the age of 13. We do not knowingly collect personal
                information from children.</h6>

            <p style="margin-bottom: 0px;"><strong>Updates to Privacy Policy:</strong></p>
            <h6>Ghar Bazaar reserves the right to modify this Privacy Policy. Users will be notified of material
                changes, providing an opportunity to review changes before continuing to use our services.</h6>

            <p style="margin-bottom: 0px;"><strong>Contact Us:</strong></p>
            <h6>If you have questions or concerns about our Privacy Policy, please contact us at <a
                    href="mailto:support@gharbazaar.com">support@gharbazaar.com</a>.</h6>
            <h6>By using Ghar Bazaar website or mobile application, users acknowledge and accept the terms outlined in this
                Privacy Policy.</h6>
        </div>
    </section>
    <!-- </Defaultlayout> -->
</template>
<script>
// import Defaultlayout from "../../layouts/Defaultlayout";
// import PageSlider from "../pages/PageSlider";
// import localconfig from "../global_settings.json"

export default {
    name: "PrivacyPolicy",
    components: {
        // Defaultlayout,
        // PageSlider,
    },

    data() {
        return {
            loading: true,
            userprofile: null,
            brand_logo: ""
        };
    },
    mounted() {
        this.brand_logo = JSON.parse(localStorage.getItem("global_settings"));
        if (localStorage.getItem("user") != null) {
            this.userprofile = JSON.parse(localStorage.getItem("user"));
        } else {
            this.userprofile = null;
        }
    },
};
</script>